import React, { useState } from "react"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import BackgroundImage from "../backgroundImage"
import ProfessionalLightbox from "../professionalLightbox"
import Edges from "../edges"

import Textarea from "../textarea"

export default ({ text, professionals }) => {
  const [lightbox, setLightbox] = useState({
    open: false,
    professional: null
  })

  // const data = useProfessionalsQuery()

  // const items = data.filter(({ ID }) => professionals.includes(ID))

  return (
    <Edges size="md">
      {/* <Container>
        {!!text && (
          <Textarea
            style={{ width: "100%", marginBottom: "25px" }}
            content={text}
          />
        )}

        {items &&
          items.map((item, index) => {
            return (
              <ImageContainer
                key={item.ID}
                onClick={() => setLightbox({ open: true, professional: item })}
                className="img-container"
                {...{ index }}
              >
                <BackgroundImage bg={item.acf.image.url} className="bg-img" />
                <ProfessionalInfo>
                  <Name children={item.post_title} variant="h3" />
                  <JobTitle children={item.acf.job_title} variant="caption" />
                </ProfessionalInfo>
              </ImageContainer>
            )
          })}
      </Container>
      <ProfessionalLightbox
        open={lightbox.open}
        onClose={() => setLightbox({ open: false, professional: null })}
        professional={lightbox.professional}
      /> */}
    </Edges>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 100px;
  /* background: white; */
`

// const TextContainer = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   padding: 5vw 4vw 8vw;

//   @media screen and (min-width: ${({ theme }) =>
//       theme.mui.breakpoints.values.sm}px) {
//     /* width: 33.3333%; */
//     width: 50%;
//   }
// `

const Headline = styled(Typography)``

const ProfessionalInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  z-index: 1;
`

const Name = styled(Typography)`
  && {
    color: #fff;
  }
`

const JobTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50vw;
  cursor: pointer;
  overflow: hidden;

  margin-bottom: 20px;

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 10px);
    height: auto;
    min-height: 300px;
  }
  &:before {
    pointer-events: none;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 35%;
    width: 100%;
    opacity: 0.5;
    z-index: 0;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 50%,
      #000000 100%
    );
  }
  .bg-img {
    transition: transform 300ms ease-in-out;
  }
  &:after {
    pointer-events: none;
    transition: opacity 300ms ease-in-out;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    opacity: 0.5;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
    .bg-img {
      transform: scale(1.02);
    }
  }

  ${({ index }) =>
    index === 0 &&
    css`
      border-right: 1px solid ${({ theme }) => theme.colors.springWood};
    `}
`

// const useProfessionalsQuery = () => {
//   const { allWordsbyCollections } = useStaticQuery(
//     graphql`
//       query {
//         allWordsbyCollections(filter: { post_type: { eq: "professionals" } }) {
//           edges {
//             node {
//               ID
//               post_title
//               acf {
//                 description
//                 job_title
//                 image {
//                   url {
//                     childImageSharp {
//                       fluid(maxWidth: 300, quality: 90) {
//                         ...GatsbyImageSharpFluid_withWebp
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     `
//   )

//   // hoist nodes to top level
//   const data = allWordsbyCollections.edges.map(node => node.node)

//   return data
// }
