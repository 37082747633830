import React from "react"
import styled, { css } from "styled-components"

import { Link } from "gatsby"
import excerptHtml from "excerpt-html"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { usePlaceholderQuery } from "../placeholder/usePlaceholderQuery"

import Edges from "../edges"

import { useAllNewsQuery } from "../hooks/useAllNewsQuery"
import TheDate from "../date"
import { GatsbyImage } from "gatsby-plugin-image"

export default function AllNews(props) {
  const { title, headline, id } = props
  const { posts } = useAllNewsQuery()
  const { allSiteOptions } = usePlaceholderQuery()

  return (
    <Container>
      {!!headline && (
        <Headline variant="h1" component="h4">
          {headline}
        </Headline>
      )}

      {!!posts &&
        posts.map((post, index) => {
          return (
            <Post key={post.title + post.title} size="lg" {...{ index }}>
              <FeaturedImage to={post.uri} className="image">
                {post.templatePost.featuredImage ? (
                  <GatsbyImage
                    image={
                      post.templatePost.featuredImage.localFile?.childImageSharp
                        ?.gatsbyImageData
                    }
                  />
                ) : (
                  <GatsbyImage
                    image={
                      allSiteOptions[0]?.themeOptions?.siteOptions
                        ?.placeholderFeaturedImage.localFile?.childImageSharp
                        ?.gatsbyImageData
                    }
                  />
                )}
              </FeaturedImage>

              <Content className="content">
                <PostDate date={post.date} />
                <div>
                  <Link to={post.uri}>
                    <PostTitle variant="h2" gutterBottom>
                      {post.title}
                    </PostTitle>
                  </Link>

                  {!!post.excerpt && (
                    <Typography gutterBottom>
                      {excerptHtml(post.excerpt)}
                    </Typography>
                  )}

                  <Link to={post.uri}>
                    <StyledButton>Read more</StyledButton>
                  </Link>
                </div>
              </Content>
            </Post>
          )
        })}

      {/* {!!previousPagePath && <Link to={previousPagePath}>Previous page</Link>}
      {!!nextPagePath && <Link to={nextPagePath}>Next page</Link>} */}
    </Container>
  )
}

const Headline = styled(Typography)`
  && {
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    padding: 30px 0;

    @media screen and (min-width: ${({ theme }) =>
        theme.mui.breakpoints.values.sm}px) {
      padding: 60px 0;
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.mui.breakpoints.values.md}px) {
      padding: 100px 0;
    }
  }
`
const Container = styled.section`
  padding-bottom: 60px;
  background: ${({ theme }) => theme.colors.springWood};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: inherit;
  }
`
const isEven = css`
  @media (min-width: 900px) {
    flex-direction: row;
    padding-left: 100px;
    .image {
      margin-right: 50px;
    }
    .content {
      margin-left: 40px;
    }
  }
`

const isOdd = css`
  @media (min-width: 900px) {
    flex-direction: row-reverse;
    /* padding-right: 100px; */
    .image {
      margin-left: 100px;
      /* padding-left: 100px; */
    }
    .content {
      margin-right: 40px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: -50vw;
    width: 180vw;
    height: 100%;
    background: white;
    z-index: 0;
  }
`

const Post = styled(Edges)`
  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    padding: 30px 0;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    padding: 60px 0;
  }
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  ${(props) => (props.index && props.index % 2 !== 0 ? isOdd : isEven)}
`

const PostTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const FeaturedImage = styled(Link)`
  @media (max-width: 899px) {
    margin-bottom: 20px;
  }
  @media (min-width: 900px) {
    width: 50%;
  }
  color: inherit;
  text-decoration: none;
  position: relative;
  z-index: 1;
  width: 100%;
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    flex-direction: row;
  }
  @media (min-width: 900px) {
    width: 50%;
    flex-shrink: 0;
  }
`
const StyledButton = styled(Button)`
  padding: 13px 45px 11px 45px;
`
const PostDate = styled(TheDate)`
  padding-right: 20px;
  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    padding-right: 30px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    padding-right: 50px;
  }
`
