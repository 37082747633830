import React from "react"
import styled from "styled-components"

import Typography from "@material-ui/core/Typography"

import Textarea from "../textarea"
import Edges from "../edges"

export default ({ items, ...rest }) => {
  //   console.log("items: ", items)
  return (
    <List {...rest} size="lg">
      {!!items &&
        items.map(({ headline, content }, index) => (
          <Item key={index}>
            <Headline variant="h3" component="div">
              {headline}
            </Headline>
            <Content {...{ content }} />
          </Item>
        ))}
    </List>
  )
}

const List = styled(Edges)`
  padding-top: 40px;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: -50vw;
    width: 160vw;
    height: 100%;
    background: #fff;
    z-index: 0;
  }
`
const Item = styled.div`
  @media (min-width: 800px) {
    flex-direction: row;
    align-items: flex-start;
    text-align: start;
  }
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
  width: 100%;
`
const Headline = styled(Typography)`
  && {
    @media (min-width: 800px) {
      padding-right: 40px;
    }
  }
`

const Content = styled(Textarea)`
  @media (min-width: 800px) {
    width: 80%;
  }
  p {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 24px;
    line-height: 34px;
  }
  p,
  h6 {
    margin-block-start: 0;
  }
  h5 {
    margin-bottom: 10px;
  }
  h6 {
    font-family: "DM Sans";
    font-size: 16px;
    color: #223244;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: normal;
  }
`
