import React from "react"
import styled from "styled-components"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import BackgroundImage from "../backgroundImage"
import Edges from "../edges"

export default function Hero(props) {
  const { image, headline, ...rest } = props

  return (
    <Container {...rest}>
      <Content>
        <Edges size="md">
          {!!headline && <Headline variant="h1" children={headline} />}
        </Edges>
      </Content>
      <ScrollDown>
        <Scroll variant="body2">SCROLL</Scroll>
      </ScrollDown>
      {!!image && <BackgroundImage bg={image} />}
    </Container>
  )
}

const headerHeight = "95px"

const Container = styled.div`
  position: relative;

  height: 100vh;
  height: calc(100vh - ${headerHeight});
  max-height: 1280px;
  min-height: 400px;

  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    z-index: 10;
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    border: 1px solid ${({ theme }) => theme.colors.springWood};
  }
`

const Content = styled.div`
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const Headline = styled(Typography)`
  && {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 40px;
    line-height: 36px;
    padding: 0 30px;

    @media screen and (min-width: ${({ theme }) =>
        theme.mui.breakpoints.values.md}px) {
      font-size: 56px;
      line-height: 52px;
    }
  }
`
const ScrollDown = styled.a`
  position: absolute;
  z-index: 5;
  transform: rotate(180deg);
  bottom: 100px;
  left: calc(50% - 12px);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    display: block;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;

    left: calc(50%);
    bottom: 120%;
    width: 1px;
    background: ${({ theme }) => theme.colors.springWood};
    height: 100px;
  }
`
const Scroll = styled(Typography)`
  && {
    color: #fff;

    font-family: "DM Sans";
    font-size: 12px;

    letter-spacing: 3px;
  }
`
