import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import BackgroundImage from "../backgroundImage"

export default ({ images }) => {
  return (
    <>
      <Container>
        {images &&
          images.map(({ link, image }, i) => {
            return (
              <ImageContainer key={i} to={link.url}>
                <BackgroundImage bg={image} className="bg-img" />
                <Headline children={link.title} variant="h1" component="p" />
              </ImageContainer>
            )
          })}
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${({ theme }) => theme.colors.springWood};
  border-top: 1px solid ${({ theme }) => theme.colors.springWood};
`

const Headline = styled(Typography)`
  && {
    @media screen and (min-width: ${({ theme }) =>
        theme.mui.breakpoints.values.sm}px) {
      text-align: start;
    }
    text-align: center;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 20px 4vw;
    text-transform: uppercase;
  }
`
const ImageContainer = styled(Link)`
  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    &:first-of-type {
      border-right: 1px solid ${({ theme }) => theme.colors.springWood};
      border-bottom: 0;
    }
  }
  &:first-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.springWood};
  }
  position: relative;
  width: 100%;
  height: 50vw;

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    width: 50%;
    height: 25vw;
  }
  .bg-img {
    transition: transform 300ms ease-in-out;
  }
  overflow: hidden;
  &:before {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    opacity: 0.6;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      #000000 100%
    );
  }
  &:after {
    pointer-events: none;
    transition: opacity 300ms ease-in-out;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    opacity: 0.5;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
    .bg-img {
      transform: scale(1.02);
    }
  }
`
