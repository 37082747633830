import React from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"

import Typography from "@material-ui/core/Typography"

import Edges from "../edges"
import Spacer from "../spacer"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Sponsors(props) {
  const { headline, sponsors } = props

  return (
    <Container>
      <Edges size="lg">
        <Spacer pt={40} pb={40}>
          {!!headline && (
            <Typography
              variant="h3"
              style={{ textAlign: "center" }}
              gutterBottom
            >
              {headline}
            </Typography>
          )}
          <Grid>
            {!!sponsors &&
              sponsors.map(({ logo, link, name }, index) => {
                const linkProps = link &&
                  link.url && {
                    href: link.url,
                    target: "_blank",
                    rel: "noopener"
                  }

                if (link?.url) {
                  return (
                    <SponsorLink key={index} {...linkProps}>
                      <LogoContainer>
                        {!!logo && (
                          <Img
                            className="img"
                            field={logo.url}
                            alt={logo.alt}
                          />
                        )}
                      </LogoContainer>
                      <Typography variant="caption" component="p">
                        {name}
                      </Typography>
                    </SponsorLink>
                  )
                } else {
                  return (
                    <Sponsor key={index}>
                      <LogoContainer>
                        {!!logo && (
                          <GatsbyImage
                            className="img"
                            image={
                              logo?.localFile?.childImageSharp?.gatsbyImageData
                            }
                            alt={logo?.altText}
                          />
                        )}
                      </LogoContainer>
                      <Typography variant="caption" component="p">
                        {name}
                      </Typography>
                    </Sponsor>
                  )
                }
              })}
          </Grid>
        </Spacer>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: 50px;
`

const SponsorCss = css`
  @media screen and (min-width: 600px) {
    width: 50%;
  }
  @media screen and (min-width: 800px) {
    width: 33.33333%;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  p {
    margin: 0 auto;
    text-align: center;
    margin-top: 10px;
  }
`

const SponsorLink = styled.a`
  ${SponsorCss}
  text-decoration: none;
  color: inherit;
`

const Sponsor = styled.div`
  ${SponsorCss}
`

const LogoContainer = styled.div``

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin-left: -25px;
  margin-right: -25px;
`
