import React from "react"
import styled, { css } from "styled-components"

import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import Edges from "../../components/edges"
import Link from "../../components/link"

export default ({ buttons, ...rest }) => {
  return (
    <Buttons {...rest} size="lg">
      {!!buttons &&
        buttons.map(({ button, size }, index) => {
          return <LinkButton key={index} {...{ size }} {...button} />
        })}
    </Buttons>
  )
}

const Buttons = styled(Edges)`
  padding: 30px 0;
`

const StyledButton = styled(Button)`
  && {
    ${props =>
      props.size === "large"
        ? css`
            padding: 20px 50px;
          `
        : css`
            padding: 11px 24px;
          `}
    ${props =>
      props.size &&
      css`
        margin-right: 20px;
      `}
  }
`

export const LinkButton = ({ url, title, size }) => {
  return (
    <Link to={url}>
      <StyledButton {...{ size }}>{title}</StyledButton>
    </Link>
  )
}
