import React from "react"
import styled from "styled-components"

import Textarea from "../textarea"
import Edges from "../edges"

export default ({ content, ...rest }) => {
  return (
    <Section {...rest}>
      <Edges size="lg">
        <Textarea className="textarea" {...{ content }} />
      </Edges>
    </Section>
  )
}

const Section = styled.section`
  background: ${({ theme }) => theme.colors.springWood};

  padding: 30px 0;

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    padding: 60px 0;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    padding: 100px 0;
  }

  .textarea {
    h5 {
      color: ${({ theme }) => theme.colors.pickledBluewood};
    }
    .intro-text {
      font-size: 24px;
      line-height: 34px;
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`
