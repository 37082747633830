import { useStaticQuery, graphql } from "gatsby"

export const useAllEventsQuery = () => {
  const { allWpEvent } = useStaticQuery(
    graphql`
      query {
        allWpEvent(sort: { fields: [date], order: ASC }) {
          events: nodes {
            title
            id
            date(formatString: "MMMM DD, YYYY")
            acfEvent {
              fieldGroupName
              endTime
              dateTime
              address
              link {
                target
                title
                url
              }
              venueName
            }
          }
        }
      }
    `
  )
  return allWpEvent
}
