import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// import app components
import FlexibleContent from "../../components/flexibleContent"

export default function home(props) {
  const {
    data: {
      page: {
        title,
        uri,
        id,
        template: {
          flexibleContent: { content }
        }
      }
    }
  } = props

  // console.log(content)
  return (
    <Container>
      {!!content && (
        <FlexibleContent
          rows={content}
          data={{
            title,
            uri,
            id
          }}
        />
      )}
    </Container>
  )
}
const Container = styled.div``

export const CollectionQuery = graphql`
  query DefaultPageTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      slug
      uri
      template {
        ... on WpDefaultTemplate {
          ...flexibleContent
        }
      }
    }
  }
`
