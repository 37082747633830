import { useStaticQuery, graphql } from "gatsby"

export const useAllProductsQuery = () => {
  const { allWpProduct } = useStaticQuery(
    graphql`
      query {
        allWpProduct(sort: { fields: [date], order: ASC }) {
          products: nodes {
            uri
            title
            id
            product {
              price
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 90
                      placeholder: BLURRED
                      formats: AUTO
                    )
                  }
                }
              }
              fieldGroupName
              description
            }
          }
        }
      }
    `
  )
  return allWpProduct
}
