import React from "react"
import styled from "styled-components"

import { GatsbyImage } from "gatsby-plugin-image"

import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import Edges from "../edges"
import Link from "../link"

export default (props) => {
  const {
    headline,
    subline,
    button,
    image
    // links, links_tagline
  } = props

  return (
    <CallToAction>
      <Edges size="lg">
        <Copy>
          {!!headline && (
            <Typography
              component="h4"
              variant="h2"
              gutterBottom
              color="secondary"
            >
              {headline}
            </Typography>
          )}

          {!!subline && <Subline>{subline}</Subline>}

          {!!button && (
            <Link to={button.url}>
              <ShopButton>{button.title}</ShopButton>
            </Link>
          )}

          {/* {!!links && (
            <ExternalServices>
              {!!links_tagline && (
                <Typography variant="caption">{links_tagline}</Typography>
              )}
              <Items>
                {links.map(({ link, image }, index) => {
                  return (
                    <Item
                      href={link.url}
                      key={index}
                      target={link.target}
                      rel="noopener"
                    >
                      <Img alt={image.alt} field={image.url} />
                    </Item>
                  )
                })}
              </Items>
            </ExternalServices>
          )} */}
        </Copy>

        {!!image && (
          <ImageWrapper>
            <AbsoluteImage
              image={image?.localFile?.childImageSharp?.gatsbyImageData}
            />
          </ImageWrapper>
        )}
      </Edges>
    </CallToAction>
  )
}

const AbsoluteImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const ImageWrapper = styled.div`
  display: none;
  @media (min-width: 800px) {
    display: block;
  }
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;

  .gatsby-image-wrapper {
    display: inline-block;
    width: 680px;
    height: 333px;
  }
`

const CallToAction = styled.section`
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.springWood};
`

const Copy = styled.div`
  position: relative;
  z-index: 1;
  @media (min-width: 800px) {
    p {
      max-width: 50%;
    }
  }
`

const Subline = styled(Typography)`
  && {
    margin-bottom: 40px;
  }
`
const ShopButton = styled(Button)`
  && {
    padding: 20px 50px;
  }
`
const ExternalServices = styled.div`
  padding-top: 40px;
`
const Items = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
`
const Item = styled.a`
  display: block;
  margin-right: 50px;
`
