import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

// import app components
import Edges from "../edges"

export default function JoinUs(props) {
  const {
    headline,
    subline,
    textColumn1,
    textColumn2,
    button,
    title,
  } = props

  return (
    <Container>
      <StyledEdges size="lg">
        {!!title && (
          <PostTitle variant="h1" component="h3">
            {title}
          </PostTitle>
        )}
        {!!headline && (
          <Headline variant="h2" component="h3" gutterBottom>
            {headline}
          </Headline>
        )}
        <TextColumns>
          {!!textColumn1 && <Column>{textColumn1}</Column>}
          {!!textColumn2 && <Column>{textColumn2}</Column>}
        </TextColumns>
        {!!subline && (
          <Subline variant="h3" gutterBottom>
            {subline}
          </Subline>
        )}
        {!!button && (
          <Link
            to={button.url}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <LargeButton>{button.title}</LargeButton>
          </Link>
        )}
      </StyledEdges>
    </Container>
  )
}
const PostTitle = styled(Typography)`
  && {
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    padding: 30px 0;

    @media screen and (min-width: ${({ theme }) =>
        theme.mui.breakpoints.values.sm}px) {
      padding: 60px 0;
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.mui.breakpoints.values.md}px) {
      padding: 100px 0;
    }
  }
`
const Container = styled.div`
  background: ${({ theme }) => theme.colors.springWood};
`
const StyledEdges = styled(Edges)`
  padding-bottom: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Headline = styled(Typography)`
  && {
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 40px;
  }
`
const Subline = styled(Typography)`
  && {
    text-align: center;
  }
`
const TextColumns = styled.div`
  @media (min-width: 800px) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
`
const Column = styled(Typography)`
  && {
    @media (min-width: 800px) {
      width: 50%;
      width: calc(50% - 12px);
    }
    &:first-of-type {
      margin-bottom: 20px;
    }
  }
`

const LargeButton = styled(Button)`
  margin-top: 20px;
  padding: 13px 45px 11px 45px;
`
