import React from "react"
import styled from "styled-components"

// import material ui
import Typography from "@material-ui/core/Typography"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"

// import app components
import BackgroundImage from "../backgroundImage"
import Textarea from "../textarea"

export default function ProfessionalLightbox({ open, onClose, professional }) {
  return (
    <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Grid container justifyContent="flex-end">
        <Close children="Close" onClick={onClose} />
      </Grid>

      {professional && (
        <Container>
          <ImageContainer>
            <BackgroundImage bg={professional.acf.image} />
          </ImageContainer>
          <Content>
            <Name children={professional.title} variant="h3" />
            <JobTitle children={professional.acf.title} variant="caption" />
            <StyledTextarea content={professional.acf.description} />
          </Content>
        </Container>
      )}
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  && {
    > div > div {
      background: transparent;
      box-shadow: none;
      border-radius: 0;
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    padding-top: 40px;
    flex-direction: row;
  }
  flex-direction: column;
`

const Content = styled.div`
  flex: 1;
`

const Name = styled(Typography)`
  && {
    color: #fff;
  }
`

const JobTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const StyledTextarea = styled(Textarea)`
  && {
    * {
      color: #fff;
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50vw;
  margin-bottom: 30px;

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    width: 250px;
    height: 250px;
    margin-right: 30px;
    margin-bottom: 0;
  }
`

const Close = styled(Typography)`
  && {
    position: relative;
    padding-left: 20px;
    color: #fff;
    text-transform: uppercase;
    font-family: "Amiri";
    cursor: pointer;

    &:before {
      content: "x";
      position: absolute;
      left: 0;
      top: 1px;
      font-size: 14px;
    }
  }
`
