import React from "react"
import styled from "styled-components"

// import app components
import BackgroundImage from "../backgroundImage"
import Textarea from "../textarea"

export default ({ row }) => {
  return (
    <>
      {row &&
        row.map((o, i) => {
          return (
            <Container key={i}>
              <TextContainer isEven={i % 2 === 0 ? "true" : "false"}>
                <Textarea content={o.text} />
              </TextContainer>
              <ImageContainer>
                <BackgroundImage bg={o.image} />
              </ImageContainer>
            </Container>
          )
        })}
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const TextContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  padding: 10vw 4vw 8vw;

  * {
    color: #fff !important;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    width: 50%;
    order: ${(props) => (props.isEven === "true" ? 0 : 2)};
    padding: 5vw 4vw 8vw;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 50vw;

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    width: 50%;
    height: auto;
    order: 1;
  }
`
