import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { useAllProductsQuery } from "../hooks/useAllProductsQuery"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import Product from "../product"
import Edges from "../../components/edges"

// import * as theme from "../../theme"

export default function AllProducts(props) {
  const { title } = props
  const { products } = useAllProductsQuery()

  return (
    <>
      <TitleSection size="md">
        <PostTitle
          variant="h1"
          children={title}
          style={{ textAlign: "center" }}
        />
      </TitleSection>
      <Edges size="md">
        <Caption>
          <Typography variant="caption">
            All prices in Canadian Dollars
          </Typography>
        </Caption>
        <Container>
          {products.map((singleProduct) => {
            return (
              <Item key={singleProduct.id}>
                <Product {...singleProduct} target="archive" />
              </Item>
            )
          })}
          <Item style={{ height: 0 }} />
          <Item style={{ height: 0 }} />
        </Container>
      </Edges>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    display: grid;
  }
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 40px;
`
const TitleSection = styled(Edges)`
  position: relative;
  margin-bottom: 20px;
  &:after {
    content: "";
    background: ${({ theme }) => theme.colors.springWood};
    position: absolute;
    height: 100%;
    width: 180vw;
    top: 0;
    left: -50vw;
    z-index: 0;
  }
`

const Item = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  background: #fff;
  margin-bottom: 20px;

  .Item {
    height: 100%;
    flex-direction: column;
  }

  .ItemContent {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ItemDescription {
    margin-bottom: 20px;
  }

  .ItemAdd {
    padding-top: 20px;
  }

  .ItemAmount {
    margin-right: 30px;
  }
  .ItemImage {
    box-shadow: 8px 20px 20px 0px rgba(0, 0, 0, 0.18);
    margin-bottom: 20px;
  }
`

const PostTitle = styled(Typography)`
  && {
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    padding: 30px 0;

    @media screen and (min-width: ${({ theme }) =>
        theme.mui.breakpoints.values.sm}px) {
      padding: 60px 0;
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.mui.breakpoints.values.md}px) {
      padding: 100px 0;
    }
  }
`

const Caption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`
