import React from "react"
import styled, { css } from "styled-components"

// import app components
import BackgroundImage from "../backgroundImage"

export default ({ images }) => {
  return (
    <Container>
      {images &&
        images.map(({ image }, index) => {
          return (
            <ImageContainer key={index} {...{ index }}>
              {!!image && <BackgroundImage bg={image} />}
            </ImageContainer>
          )
        })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: ${({ theme }) => theme.mui.breakpoints.values.sm}px) {
    border-top: 1px solid ${({ theme }) => theme.colors.springWood};
    border-bottom: 1px solid ${({ theme }) => theme.colors.springWood};
  }
`

const ImageContainer = styled.div`
  @media (min-width: 800px) {
    ${({ index }) =>
      index === 1 &&
      css`
        border-right: 1px solid ${({ theme }) => theme.colors.springWood};
        border-left: 1px solid ${({ theme }) => theme.colors.springWood};
      `}
  }
  width: 100%;
  height: 65vw;

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    width: 33.3333%;
    height: 25vw;
  }

  @media (max-width: ${({ theme }) => theme.mui.breakpoints.values.sm - 1}px) {
    ${({ index }) =>
      index === 2 &&
      css`
        display: none;
      `}
  }
`
