import React from "react"
import styled from "styled-components"

import BackgroundImage from "../backgroundImage"

export default ({ image, imageSize, background_color, ...rest }) => {
  return (
    <Banner backgroundcolor={background_color} {...rest}>
      {!!image && <BackgroundImage bg={image} size={imageSize} />}
    </Banner>
  )
}

const Banner = styled.div`
  height: 376px;
  background: ${props => props.backgroundcolor || "#ffffff"};
`
