import React from "react"

import Form from "../form"
import Hero from "../hero"
import MapBox from "../map"
import LinkBoxes from "./LinkBoxes"
import Banner from "./Banner"
import CallToAction from "./CallToAction"
import TextArea from "./TextBlock"
import Images from "./Images"
import TextImage from "./TextImage"
import ProfessionalsTeaser from "./ProfessionalsTeaser"
import ImageTeaser from "./ImageTeaser"
import Events from "./Events"
import List from "./List"
import Buttons from "./Buttons"
import Sponsors from "./Sponsors"
import JoinUs from "./JoinUs"
import AllProducts from "./AllProducts"
import AllNews from "./AllNews"

export default (props) => {
  const { rows, data } = props

  if (!!rows) {
    return rows
      .filter((o) => o !== null)
      .map(({ fieldGroupName, ...rowData }, index) => {
        if (!fieldGroupName) {
          return null
        }
        const type = fieldGroupName.split("_").slice(-1)[0]
        const components = {
          Form,
          Hero,
          MapBox,
          LinkBoxes,
          Banner,
          CallToAction,
          TextArea,
          Images,
          TextImage,
          ProfessionalsTeaser,
          ImageTeaser,
          Events,
          List,
          Buttons,
          Sponsors,
          JoinUs,
          AllProducts,
          AllNews
        }
        const Component = components[type]
        return (
          Component && (
            <Component
              key={index}
              firstItem={index === 0 ? true : false}
              {...rowData}
              {...data}
            />
          )
        )
      })
  }
}
