import React, { useRef } from "react"
import styled, { css } from "styled-components"
import { useAllEventsQuery } from "../hooks/useAllEventsQuery"

import moment from "moment"
import { BoxLoader } from "react-loaders-spinners"

// import material ui
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ArrowUp from "react-ionicons/lib/IosArrowUp"
import ArrowDown from "react-ionicons/lib/IosArrowDown"
import isBrowser from "../../utils/isBrowser"

// import app components
import Edges from "../edges"
import BackgroundImage from "../backgroundImage"
import TheDate from "../date"

import useFetch from "../../utils/useFetch"

export default function Events(props) {
  const { events } = useAllEventsQuery()
  const UpcomingEvents = events.filter(
    (event) =>
      event?.acfEvent?.dateTime && moment(event?.acfEvent?.dateTime) > moment()
  )

  const isFrontPage = props?.uri === "/"
  const containerRef = useRef(null)
  const listRef = useRef(null)

  const handleScroll = (direction) => {
    if (containerRef !== null) {
      if (direction === "up") {
        //* scroll up
        containerRef.current.scrollBy({
          top: -listRef.current.lastChild.clientHeight,
          left: 0,
          behavior: "smooth"
        })
      } else if (direction === "down") {
        //* scroll down
        containerRef.current.scrollBy({
          top: listRef.current.lastChild.clientHeight,
          left: 0,
          behavior: "smooth"
        })
      }
    }
  }

  return (
    <>
      <Container {...{ isFrontPage }} ref={containerRef}>
        {!!isFrontPage && (
          <Controls size="md">
            <button onClick={() => handleScroll("up")}>
              <ArrowUp />
            </button>
            <button onClick={() => handleScroll("down")}>
              <ArrowDown />
            </button>
          </Controls>
        )}
        <Edges size="md">
          <List ref={listRef} {...{ isFrontPage }}>
            <>
              {!!isFrontPage && (
                <>
                  <Headline variant="h1">EVENTS CALENDAR</Headline>
                </>
              )}

              {!events?.length > 0 && (
                <Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      padding: "80px 0"
                    }}
                  >
                    <Typography variant="h2" gutterBottom>
                      <BoxLoader pColor="white" />
                    </Typography>
                  </div>
                </Item>
              )}

              {UpcomingEvents &&
                UpcomingEvents?.length > 0 &&
                UpcomingEvents?.map(
                  (
                    {
                      id,
                      title,
                      acfEvent: { dateTime, endTime, venueName, address, link }
                    },
                    index
                  ) => {
                    const time = moment(dateTime).format("h:mm a")

                    return (
                      <Item key={id}>
                        <Date date={dateTime} />

                        <Content>
                          <Typography
                            variant="h2"
                            children={title}
                            gutterBottom
                          />
                          <Typography color="inherit">
                            {time} {!!endTime && <>&ndash; {endTime}</>}
                          </Typography>
                          <Location children={venueName} />
                          <Typography children={address} />

                          {!!link && (
                            <StyledLink href={link.url} target="_blank">
                              <StyledButton children={link.title} />
                            </StyledLink>
                          )}
                        </Content>
                      </Item>
                    )
                  }
                )}
            </>

            {!events?.length > 0 && (
              <Item>
                <Typography variant="h2" gutterBottom>
                  No upcoming events
                </Typography>
              </Item>
            )}
          </List>
        </Edges>
      </Container>
      {!!props?.image && (
        <ImageContainer>
          <BackgroundImage bg={props?.image} />
        </ImageContainer>
      )}
    </>
  )
}

const Controls = styled(Edges)`
  transform: translateX(90px) translateY(16px);
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  button {
    svg {
      width: 35px;
      height: 35px;
      path {
        fill: white;
      }
    }
    border: none;
    margin: 0;
    padding: 5px;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    &:hover {
      cursor: pointer;
    }
  }
`

const Headline = styled(Typography)`
  && {
    color: white;
    margin-bottom: 0;

    @media screen and (min-width: ${({ theme }) =>
        theme.mui.breakpoints.values.md}px) {
      margin-bottom: -6px;
    }
    @media screen and (min-width: ${({ theme }) =>
        theme.mui.breakpoints.values.lg}px) {
      margin-bottom: -8px;
    }
  }
`

const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.pickledBluewood};

  ${({ isFrontPage }) =>
    !!isFrontPage
      ? css`
          max-height: 90vh;
          overflow-x: hidden;
          overflow-y: scroll;
          padding-top: 60px;
        `
      : css`
          padding: 3vw 0;
          @media screen and (min-width: ${({ theme }) =>
              theme.mui.breakpoints.values.md}px) {
            margin-bottom: -80px;
            padding: 0;
          }
        `}
`

const List = styled.div`
  ${({ isFrontPage }) =>
    !!isFrontPage
      ? css`
          transform: translateY(-98px);
        `
      : css`
          @media screen and (min-width: ${({ theme }) =>
              theme.mui.breakpoints.values.md}px) {
            transform: translateY(80px);
          }
        `}
  position: relative;
  z-index: 1;
`

const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5vw;
  background: ${({ theme }) => theme.colors.secondary};
  border: 1px solid ${({ theme }) => theme.colors.watermelon};
  margin-bottom: -1px;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    flex-direction: row;
    padding: 35px 100px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
  }

  && {
    * {
      color: #fff;
    }
  }
`

const Date = styled(TheDate)`
  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    margin: 0 50px 0 0;
  }
`

const Content = styled.div`
  flex: 1;
`

const Location = styled(Typography)`
  && {
    font-family: "Amiri";
    text-transform: uppercase;
    font-weight: bold;
    font-family: Amiri-Bold;
    letter-spacing: 0.5px;
  }
`

const StyledLink = styled.a`
  display: inline-block;
  margin-top: 30px;
  text-decoration: none;
`

const StyledButton = styled(Button)`
  && {
    padding: 10px 25px 8px;
    border: 1px solid #fff;

    &:hover {
      background: #fff;
      span {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 300px;

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    height: 400px;
  }
`
