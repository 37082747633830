import { useStaticQuery, graphql } from "gatsby"

export const useAllNewsQuery = () => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(sort: { fields: [date], order: DESC }) {
          posts: nodes {
            id
            uri
            date(formatString: "MMMM DD, YYYY")
            title
            excerpt
            templatePost {
              fieldGroupName
              featuredImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 90
                      placeholder: BLURRED
                      formats: AUTO
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allWpPost
}
